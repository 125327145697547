<template>
  <div class="tabs-wrap" style="height:100%">
    <en-table-layout :table-data="tableData.data" :row-key="row => row.id" ref="tableData">
      <div slot="header" style="padding: 0 13px;" class="row no-gutters align-items-center">
        <el-tabs v-model="currentTab" @tab-click="tabChange" type="card">
          <el-tab-pane label="卡密管理" lazy> </el-tab-pane>
          <el-tab-pane label="在线发放" lazy> </el-tab-pane>
        </el-tabs>
        <div style="width:100%;display:flex;align-items: center;">
          <el-input style="width: 300px;" size="medium" placeholder="请输入..." v-model.trim="keywords" clearable>
            <el-select v-model="key_word" slot="prepend" placeholder="请选择" style="width: 120px;">
              <el-option label="卡号" value="card_code"></el-option>
              <el-option label="卡密" value="card_key"></el-option>
            </el-select>
          </el-input>
          <div><el-button style="margin-right: 20px;" class="ml-3" @click="search" type="primary"
              size="small">搜索</el-button></div>
          <create-card-key-button v-if="currentTab === '0'" />
          <create-online-grant-button v-else />
        </div>
      </div>
      <template slot="table-columns">
        <el-table-column :key="'code'" :label="currentTab == 0 ? '生成方式' : '发放名单来源'" min-width="50"
          :show-overflow-tooltip="true">
          <template slot-scope="{row}">{{ row.create_type | cardStatus }}</template>
        </el-table-column>
        <el-table-column prop="exchange_time" label="生成时间" min-width="100" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <template v-if="scope.row.create_time">{{ scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm")
            }}</template>
            <template v-else>————</template>
          </template>
        </el-table-column>

        <el-table-column :key="'key_num'" prop="key_num" label="总数" width="180" />
        <el-table-column v-if="card_type !== 5" :key="'all_num'" prop="all_num" label="总次数" width="150" />
        <el-table-column :key="'active_num'" prop="active_num" label="已激活数" width="140" />
        <el-table-column v-if="currentTab == 0" :key="'binding_num'" prop="binding_num" label="已绑定数" width="120" />
        <el-table-column label="操作" width="210">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="remarkOpen(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_Card from "@/api/combo-card-shop/card";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import CreateOnlineGrantButton from "./components/components/create-online-grant-button.vue";
import CreateCardKeyButton from "./components/components/create-card-key-button.vue";
import mixin from "./components/mixin";

export default {
  name: "cardGrantList",
  components: {
    EnTableLayout,
    CreateOnlineGrantButton,
    CreateCardKeyButton,
  },
  mixins: [mixin],
  filters: {
    /** 生成方式格式化 */
    cardStatus (create_type) {
      switch (create_type) {
        case 1:
          return '系统生成'
        case 2:
          return '表格导入'
        default:
          return '历史数据'
      }
    },
  },
  data () {
    return {
      currentTab: "0",
      card_type: null,//商城类型
      keywords: "",
      key_word: 'card_code',
      params: {
        card_id: this.$route.params.card_id,
        page_no: 1,
        page_size: 20
      },
      tableData: {}
    }
  },
  mounted () {
    this.card_type = this.$route.params.card_type;
    this.Get_TableData();
    _eventEmitter.on("card-grant-list-active", this.Get_TableData_Wrap);
  },
  beforeDestroy () {
    _eventEmitter.off("card-grant-list-active");
  },
  methods: {
    tabChange () {
      this.keywords = "";
      this.key_word = 'card_code';
      this.Get_TableData();
    },
    /**
     * 获取列表数据
     */
    Get_TableData () {
      let params = JSON.parse(JSON.stringify(this.params))
      params[this.key_word] = this.keywords
      const apis = this.currentTab == 0 ? 'gitsellerCard' : 'getProvideLog'
      API_Card[apis](params).then(res => {
        this.tableData = res;
      });
    },
    handleSizeChange (val) {
      this.params.page_size = val;
      this.Get_TableData();
    },
    handleCurrentChange (val) {
      this.params.page_no = val;
      this.Get_TableData();
    },
    remarkOpen (row) {//查看
      let name =
        row.card_type == 7 ? 'homecard-grant' : 'card-grant';
      if (row.create_type == 0) {//如果是历史数据查看 要传card_id卡密管理log id=0在线发放传provide log_id=0
        row.id = 0
      }
      this.$router.push({
        path: `/combo-card-shop/card/${name}/${row.card_id}/${row.id}/${row.card_type}?active=${this.currentTab}`
      });
      // this.$router.push({
      //   name: name,
      //   params: { log_id: row.id, card_id: row.card_id, card_type: row.card_type,active:this.currentTab }
      // })
    },
  }
}
</script>

<style lang="scss" scoped>
.card-input {
  display: inline-block;
  margin-right: 20px;

  .card-code-prompt-text {
    position: absolute;
    font-size: 12px;
    color: red;
    z-index: 99;
  }
}

.tabs-wrap {
  background: #fff;

  ::v-deep .el-tab-pane {}
}

::v-deep {
  .el-tabs--card>.el-tabs__header {
    // border-bottom: 0px;
  }

  .el-tabs--card>.el-tabs__header .el-tabs__item {
    border-bottom-color: #e4e7ed;
    background-color: #fafafa;
  }

  .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    border-bottom-color: #fff;
    background-color: #fff;
  }

  .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
  }
}
</style>
