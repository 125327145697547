<template>
  <div class="cash-coupon-manager">
    <el-button
      @click="handleCreatCardKey"
      size="small"
      type="primary"
    >
      添加卡密
    </el-button>

    <!-- 添加卡密 Dialog -->
    <el-dialog-x-dialog
      :close-on-click-modal="false"
      :append-to-body="true"
      title="生成卡密"
      :visible.sync="createCardKeyVisible"
      width="580px"
    >
      <el-tabs
        v-model="cardKeyType"
        type="card"
      >
        <el-tab-pane
          label="系统生成卡密"
          name="1"
        >
          <div class="tipBox">
            <p>*输入生成数量，即可一键生成卡密</p>
            <p>*导入的卡密默认为未激活状态</p>
          </div>
          <div class="addMealForm">
            <el-form
              :model="createCardKeysParams"
              :rules="createCardKeysRules"
              ref="createCardKeysParams"
              label-width="110px"
            >
              <el-form-item
                class="w200"
                label="生成数量"
                prop="num"
              >
                <el-input-number
                  :min="1"
                  style="width: 200px"
                  :controls="false"
                  v-model="createCardKeysParams.num"
                >
                </el-input-number>
                张
              </el-form-item>
              <el-form-item
                label="卡号前缀"
                prop="prefix_code"
              >
                <el-input
                  style="width: 200px"
                  placeholder="请输入4位以内的字母或数字"
                  v-model="createCardKeysParams.prefix_code"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="表格导入卡密"
          name="2"
          class="pan-wrap"
        >
          <div class="tipBox b1">
            <p>*您可导入卡号+卡密或仅卡密</p>
            <p>*卡号和卡密都不可重复</p>
            <p>*导入的卡密默认为未激活状态</p>
            <p>*卡号初始为6~10位字符，由前缀+6位按顺序生成数字组成（超过6位后自动进位）。可不填写前缀。</p>
            <p>*卡号前缀由4位以内的字母组成，字母区分大小写。</p>
            <p>*卡密为16位数字+字母组成，字母不区分大小写。</p>
          </div>
          <div class="shep">第一步：根据需求，勾选下列内容，形成表格模板</div>
          <el-radio-group v-model="downLoadType">
            <el-radio :label="0">仅卡密</el-radio>
            <el-radio :label="1">卡号+卡密</el-radio>
          </el-radio-group>
          <br />
          <el-button
            style="margin-top: 10px; margin-bottom: 15px"
            type="default"
            size="small"
            @click="downloadEmptyTemplate"
          >下载表格模板
          </el-button>
          <div class="shep">
            第二步：编辑表格，请不要修改/删除表格中的表头文字，且不要修改表格的格式。表格内容不可超过3万条
          </div>
          <div class="shep">第三步：导入表格</div>
          <upload-excel-component
            defaultSize
            :on-success-tem="excelSuccess"
            showFileName
          />
        </el-tab-pane>
        <div class="dialog-footer">
          <el-button
            type="default"
            @click="createCardKeyVisible = false"
            size="small"
          >取消
          </el-button>
          <el-button
            type="primary"
            v-if="cardKeyType === '1'"
            @click="submitCreateCardKeys"
            size="small"
          >确定
          </el-button>
          <el-button
            type="primary"
            v-if="cardKeyType === '2'"
            @click="submitImportCardKeys"
            size="small"
          >确定
          </el-button>
        </div>
      </el-tabs>
    </el-dialog-x-dialog>

  </div>
</template>

<script>
import * as API_Card from "@/api/combo-card-shop/card";
import UploadExcelComponent from "@/components/UploadExcel";
import { downloadEmptyTemplate, selfDefinedTemplate } from "@/utils";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import mixin from "../mixin.js";

export default {
  name: "createCardKeyButton",
  components: {
    EnTableLayout,
    UploadExcelComponent,
  },
  mixins: [mixin],
  data() {
    return {
      createCardKeyVisible: false,
      cardKeyType: '1',
      createCardKeysParams: {
        card_id: this.$route.params.card_id,
        num: "",
        prefix_code: "",
      },
      createCardKeysRules: {
        num: [
          {
            required: true,
            message: "请输入生成数量",
            trigger: "change",
          },
        ],
      },
      downLoadType: 0,
      importCardKeysParams: {
        card_id: this.$route.params.card_id,
        cards_key_dolist: [],
        prefix: 0, // 0 仅卡密 1 卡号+卡密
      },
    };
  },
  mounted() {},
  methods: {
    /**
     * 模板下载
     */
    downloadEmptyTemplate() {
      selfDefinedTemplate(
          [],
          this.downLoadType === 0 ? ["卡密"] : ["卡号", "卡密", "卡号前缀"],
          [],
          "卡密导入模板",
          false
        );
      // downloadEmptyTemplate(
      //   "卡密导入模板",
      //   this.downLoadType === 0 ? ["卡密"] : ["卡号", "卡密", "卡号前缀"],
      //   false
      // );
    },
    /**
     * 添加卡密
     */
    handleCreatCardKey() {
      this.createCardKeyVisible = true;
    },
    /**
     * 系统生成卡密
     */
    submitCreateCardKeys() {
      API_Card.createCardKeys(this.createCardKeysParams).then((res) => {
        this.$message.success('卡密生成成功')
        this.createCardKeyVisible = false;
        _eventEmitter.emit('card-grant-list-active')
      });
    },
    excelSuccess({results,shop_name,card_name}) {
      console.log({results,shop_name,card_name})
      this.importCardKeysParams.cards_key_dolist = [];
      this.importCardKeysParams.shop_name = shop_name
      this.importCardKeysParams.card_name = card_name
      results.map((item) => {
        this.importCardKeysParams.cards_key_dolist.push({
          card_code: item["卡号"],
          card_key: item["卡密"],
          code_prefix: item["卡号前缀"],
        });
      });
    },
    /**
     * 表格导入卡密
     */
    submitImportCardKeys() {
      if (
        !this.importCardKeysParams.cards_key_dolist[0] ||
        (this.downLoadType &&
          !this.importCardKeysParams.cards_key_dolist[0].card_code) ||
        (!this.downLoadType &&
          !this.importCardKeysParams.cards_key_dolist[0].card_key)
      ) {
        this.$message.error("导入的数据模板有误，请重新导入");
        return;
      }
      this.importCardKeysParams.prefix = this.downLoadType;
      API_Card.importCardKeys(this.importCardKeysParams).then(res => {
        this.createCardKeyVisible = false;
        this.importAfter(res, () => _eventEmitter.emit('card-grant-list-active'));
      });
    },
  },
};
</script>

<style lang="scss">
.pan-wrap {
  height: calc(100vh - 410px);
}
.tipBox{
  p {
    font-size: 12px;
    margin-bottom: 0.5rem;
  }
}
@media screen and (max-width: 1366px) {
    .pan-wrap {
      height: 380px;
    }
}
.dialog-footer{
  padding: 0 0 20px 0;
  text-align: right;
}
#spinner {
  display: inline-block;
  height: 1em;
  line-height: 1;
  text-align: left;
  vertical-align: -0.25em;
  overflow: hidden;
}
#spinner::before {
  display: block;
  content: "...\A..\A.";
  white-space: pre-wrap; /* 也可以是white-space: pre */
  animation: dot 3s infinite step-start both;
}

@keyframes dot {
  33% {
    transform: translateY(-2em);
  }
  66% {
    transform: translateY(-1em);
  }
}
</style>
