<template>
  <div class="cash-coupon-manager">
    <el-button @click="handleCreatOnlineGrant" size="small" type="primary">
      新建发放
    </el-button>

    <!-- 新建发放 Dialog -->
    <el-dialog-x-dialog :close-on-click-modal="false" :append-to-body="true" title="在线发放"
      :visible.sync="createOnlineGrantVisible" width="60%">
      <el-tabs v-model="createGrantType" type="card">
        <el-tab-pane label="发放给系统用户" name="1" class="online-grant-pan">

          <en-table-layout ref="multipleTable" :table-data="memberList.data" @selection-change="memberSelectChange"
            :row-key="row => row.member_id" class="table-wrap">
            <template slot="toolbar">
              <el-form-item>
                <el-input v-model="memberParams.keyword" placeholder="请输入用户名/手机号" clearable size="mini" />
              </el-form-item>
              <el-form-item>
                <el-button @click="Get_MemberList" size="mini" type="primary">
                  搜索
                </el-button>
              </el-form-item>
            </template>
            <template slot="table-columns">
              <el-table-column :key="'slct'" type="selection" :reserve-selection="true" width="55"></el-table-column>
              <el-table-column :key="'uname'" prop="uname" label="用户姓名"></el-table-column>
              <el-table-column :key="'mob'" prop="mobile" label="手机号"></el-table-column>
            </template>
            <el-pagination slot="pagination" @size-change="handleGetMemberSizeChange"
              @current-change="handleGetMemberCurrentChange" :current-page="memberList.page_no"
              :page-size="memberList.page_size" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
              :total="memberList.data_total" />
          </en-table-layout>

        </el-tab-pane>
        <el-tab-pane label="表格导入发放名单" name="2">
          <div class="tipBox b2">
            <p>*姓名可以为空，手机号不能为空</p>
            <p>*导入的数据中未注册用户会添加到用户列表中。当新用户通过微信登录前台商城时，系统会自动获取其微信名称作为系统用户名称。</p>
          </div>
          <div class="shep">第一步：下载表格模板</div>
          <el-button style="margin-top: 10px; margin-bottom: 15px" type="default" size="small"
            @click="downloadEmptyTemplate">下载表格模板
          </el-button>
          <div class="shep">
            第二步：编辑表格，请不要修改/删除表格中的表头文字，且不要修改表格的格式，表格内容不可超过3万条。
          </div>
          <div class="shep">第三步：导入表格</div>
          <upload-excel-component defaultSize :on-success-tem="excelSuccess" showFileName />
        </el-tab-pane>
      </el-tabs>
      <div style="margin-top: 0px">
        <el-form inline style="height:30px;">
          <el-form-item>
            <el-checkbox v-model="activeChecked" @change="activeCheckboxChange">是否直接激活
            </el-checkbox>
          </el-form-item>
          <template v-if="activeChecked">
            <el-form-item label="业务员名称" required>
              <el-input v-model="activeParams.sales_man" placeholder="业务员名称" clearable size="mini" />
            </el-form-item>
            <el-form-item label="所属销售公司" required>
              <el-input v-model="activeParams.company" placeholder="所属销售公司" clearable size="mini" />
            </el-form-item>
          </template>
          <el-form-item>
            <el-checkbox v-model="msgChecked" @change="msgCheckboxChange">短信通知
            </el-checkbox>
          </el-form-item>
        </el-form>
        <div class="msg-hint">
          【短信签名】发券通知：尊敬的客户，您收到一张卡券，请使用此手机号或卡密[卡密]，前往 [链接] 进行兑换，即日起2年有效期，如有疑问请联系客服[客服电话]。
        </div>
      </div>
      <div class="dialog-footer">
        <el-button type="default" size="small" @click="createOnlineGrantVisible = false">
          取消
        </el-button>
        <el-button type="primary" v-show="createGrantType === '1'" size="small" @click="submitGrantMember">
          确定
        </el-button>
        <el-button type="primary" v-show="createGrantType === '2'" size="small" @click="submitGrantImportMember">
          确定
        </el-button>
      </div>
    </el-dialog-x-dialog>

  </div>
</template>

<script>
import * as API_HotGoods from "@/api/hotGoods";
import * as API_Card from "@/api/combo-card-shop/card";
import UploadExcelComponent from "@/components/UploadExcel";
import { downloadEmptyTemplate, selfDefinedTemplate } from "@/utils";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import mixin from "../mixin.js";

export default {
  name: "createOnlineGrantButton",
  components: {
    EnTableLayout,
    UploadExcelComponent,
  },
  mixins: [mixin],
  data () {
    return {
      id: null, // 从卡券列表页面跳转过来的卡券ID
      createGrantType: "1",
      memberParams: {
        page_no: 1,
        page_size: 20,
        keyword: "",
      },
      memberList: {},
      memberSelectedList: [],
      createOnlineGrantVisible: false,
      dialogShow: false,
      submitCreateGrantParams: {
        card_id: "", // 卡券id
        member_ids: [], // 用户id
        member_names: [], // 用户姓名集合
        phones: [], // 用户手机号集合
        send_message: 0, // 是否发送短信   1发
        active: 0, // 是否直接激活 1激活 0不激活
      },
      activeChecked: false,
      msgChecked: false,
      cardMemberVO: {
        card_id: "",
        member_list: {},
        send_message: 0, // 是否发送短信 1发 0不发
        active: 0, // 是否直接激活 1激活 0不激活
      },
      name: "", //搜索框数据
      activeParams: {
        sales_man: "",
        company: "",
      },
    };
  },
  mounted () {
    this.id = this.$route.params.card_id;
    // this.Get_MemberList();
  },
  methods: {
    /**
     * 在线发放-表格导入发放名单
     */
    downloadEmptyTemplate () {
      selfDefinedTemplate(
        [],
        ["姓名", "手机号"],
        [],
        "表格导入发放名单模板",
        false
      );
    },
    /**
     * 是否发送短信多选框判断
     */
    msgCheckboxChange () {
      if (this.msgChecked) {
        this.submitCreateGrantParams.send_message = 1;
        this.cardMemberVO.send_message = 1;
      } else {
        this.submitCreateGrantParams.send_message = 0;
        this.cardMemberVO.send_message = 0;
      }
    },
    /**
     * 是否直接激活
     */
    activeCheckboxChange () {
      if (this.activeChecked) {
        this.submitCreateGrantParams.active = 1;
        this.cardMemberVO.active = 1;
        console.log(this.activeParams)
        if (!this.activeParams.sales_man && !this.activeParams.company) {
          API_Card.getOneCardTh(this.id).then(res => {
            this.activeParams = {
              sales_man: res.salesman_name,
              company: res.client_name
            }
            console.log(res)
          })
        }
      } else {
        this.submitCreateGrantParams.active = 0;
        this.cardMemberVO.active = 0;
      }
    },
    /**
     * 在线发放-新建发放按钮
     */
    handleCreatOnlineGrant () {
      // this.dialogShow = true;
      this.createOnlineGrantVisible = true;
      this.Get_MemberList();
    },
    /**
     * 发放给系统用户-用户列表
     */
    Get_MemberList () {
      API_HotGoods.getMemberList(this.memberParams).then((res) => {
        this.memberList = res;
      });
    },
    /**
     * 发放给系统用户-用户列表分页大小
     */
    handleGetMemberSizeChange (val) {
      this.memberParams.page_size = val;
      this.Get_MemberList();
    },
    /**
     * 发放给系统用户-用户列表当前页码
     */
    handleGetMemberCurrentChange (val) {
      this.memberParams.page_no = val;
      this.Get_MemberList();
    },
    /**
     * 发放给系统用户-选择改变
     */
    memberSelectChange (val) {
      this.memberSelectedList = JSON.parse(JSON.stringify(val));
    },
    /**
     * 发放给系统用户确认按钮
     */
    submitGrantMember () {
      if (this.memberSelectedList.length === 0) {
        this.$message.error("请选择发放的用户");
        return;
      }
      if (
        this.activeChecked &&
        (!this.activeParams.sales_man || !this.activeParams.company)
      ) {
        this.$message.error("请填写业务员名称和销售公司");
        return;
      }
      this.submitCreateGrantParams.sales_man = this.activeParams.sales_man;
      this.submitCreateGrantParams.company = this.activeParams.company;
      this.submitCreateGrantParams.card_id = this.id;
      this.submitCreateGrantParams.member_ids = [];
      this.submitCreateGrantParams.member_names = [];
      this.submitCreateGrantParams.phones = [];
      this.memberSelectedList.map((item) => {
        this.submitCreateGrantParams.member_ids.push(item.member_id);
        this.submitCreateGrantParams.member_names.push(item.nickname);
        this.submitCreateGrantParams.phones.push(item.mobile);
      });
      API_Card.bindingMembers(this.submitCreateGrantParams).then((res) => {
        this.$message({
          title: "提示",
          message: "成功发放",
          type: "success",
        });
        this.createOnlineGrantVisible = false;
        // this.$refs.multipleTable.clearSelection();
        this.memberSelectedList = [];
        this.msgChecked = false;
        this.activeChecked = false;
        this.submitCreateGrantParams.active = 0;
        this.cardMemberVO.active = 0;
        this.submitCreateGrantParams.send_message = 0;
        this.cardMemberVO.send_message = 0;
        this.activeParams.sales_man = "";
        this.activeParams.company = "";
        _eventEmitter.emit("card-grant-list-active");
      });
    },
    /**
     * 表格导入发放名单-导入成功
     */
    excelSuccess ({ results, shop_name, card_name }) {
      let templateHedaer = ["姓名", "手机号"];
      let templateKey = ["nickname", "mobile"];
      let resultsList = [];
      results.forEach((list, idx) => {
        // templateKey.forEach((item, index) => {
        //   list = JSON.parse(
        //     JSON.stringify(list).replace(templateHedaer[index], item)
        //   );
        // });
        resultsList.push({ nickname: list["姓名"], mobile: list["手机号"], });
      });
      console.log(results, shop_name, card_name, resultsList)
      this.cardMemberVO.card_id = this.id;
      this.cardMemberVO.member_list = resultsList;
      this.cardMemberVO.shop_name = shop_name
      this.cardMemberVO.card_name = card_name
    },
    /**
     * 表格导入发放名单确认按钮
     */
    submitGrantImportMember () {
      if (!this.cardMemberVO.card_id) {
        this.$message.error("请上传导入名单");
        return;
      }
      if (
        this.activeChecked &&
        (!this.activeParams.sales_man || !this.activeParams.company)
      ) {
        this.$message.error("请填写业务员名称和销售公司");
        return;
      }
      this.cardMemberVO.sales_man = this.activeParams.sales_man;
      this.cardMemberVO.company = this.activeParams.company;
      console.log(123)
      API_Card.importBindingMembers(this.cardMemberVO).then((res) => {
        this.cardMemberVO.member_list = {};
        this.cardMemberVO.card_id = "";
        this.createOnlineGrantVisible = false;
        this.msgChecked = false;
        this.activeChecked = false;
        this.submitCreateGrantParams.active = 0;
        this.cardMemberVO.active = 0;
        this.submitCreateGrantParams.send_message = 0;
        this.cardMemberVO.send_message = 0;
        this.activeParams.sales_man = "";
        this.activeParams.company = "";
        this.importAfter(res, () => _eventEmitter.emit("card-grant-list-active"));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.online-grant-pan {
  height: calc(100vh - 410px);
}

@media screen and (max-width: 1366px) {
  .online-grant-pan {
    height: 330px;
  }
}

.table-wrap ::v-deep .en-table-layout-toolbar {
  padding: 0;
  padding-bottom: 2px;
}

.msg-hint {
  padding: 10px 0;
  font-size: 12px;
}

.dialog-footer {
  padding: 0 0 15px 0;
  text-align: right;
}

#spinner {
  display: inline-block;
  height: 1em;
  line-height: 1;
  text-align: left;
  vertical-align: -0.25em;
  overflow: hidden;
}

#spinner::before {
  display: block;
  content: "...\A..\A.";
  white-space: pre-wrap;
  /* 也可以是white-space: pre */
  animation: dot 3s infinite step-start both;
}

@keyframes dot {
  33% {
    transform: translateY(-2em);
  }

  66% {
    transform: translateY(-1em);
  }
}
</style>
